import React, { useEffect, useState, useMemo, type FC } from 'react'
import { useLocation } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Avatar } from '@mui/material';
import { t } from 'i18next';

import { FabComponent } from '../FabComponent/FabComponent'
import { useTheme } from '../ThemeProvider/ThemeProvider';
import { TransactionModalContext, MenuSettingsComponent, useSnackbar, type PositionType } from '../../components';
import { DepositComponent } from '../../pages/Accounts/DepositSection/DepositComponent';
import { WithdrawalComponent } from '../../pages/Accounts/WithdrawalSection/WithdrawalComponent';
import { getAccounts as getAllAccounts, type IAccount } from '../../redux/reducers/accounts';
import { getTotalBalance, getTotalCredit } from '../../redux/reducers/cfd';
import { getHeaderCurrencyRates } from '../../redux/reducers/currencyRates';
import { useAppSelector, useAppDispatch } from '../../hooks/redux'
import { toFixed, getCurrencySymbol } from '../../helpers/util';
import { selectUserProfileImage } from '../../redux/selectors/user';
import { socket } from '../../web/socket'
import { AuthData } from '../../auth/AuthWrapper';
import { type SetStateAction } from '../PasswordInputComponent/PasswordInputComponent';
import { LogoIcon } from '../icons/LogoIcon';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';
import { SelectAccountHeader } from './SelectAccountHeader/SelectAccountHeader';
import { selectIsAlertMessage, selectIsRunningAdvertisement } from '../../redux/selectors/alertMessage';
import reciveDeposit from '../../assets/images/recive_deposit.svg';
import { DialogContextProvider } from '../../pages/Accounts/context/DialogContext';

import style from './Header.module.css'
import { getAutotrader } from '../../redux/reducers/autotrader';

interface InterfaceHeader {
  openMenu: boolean
  setOpenMenu: (el: SetStateAction<boolean>) => void
}

interface IAcountInfoValue {
  label: string
  value: string
  style?: Record<string, string>
}

export const accountSelectBtnIconStyle = {
  color: 'var(--CFD-theme-System-Secondary)',
  width: '20px',
  height: '20px'
}

export const Header: FC<InterfaceHeader> = ({ openMenu, setOpenMenu }) => {
  const dispatch = useAppDispatch();
  const { handleOpen } = useSnackbar();
  const { logout } = AuthData();
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  const [openWithdrawal, setOpenWithdrawal] = useState<boolean>(false);
  const [openSelectAccounts, setOpenSelectAccounts] = useState<boolean>(false);
  const [pnl, setPnl] = useState<number>(0);
  const [chosenSelectedAccount, setChosenSelectedAccount] = useState<IAccount | null>(null);
  const { theme } = useTheme();
  const { _id: userId, firstName, lastName, brand } = useAppSelector((state) => state.user);
  const accounts = useAppSelector((state) => state.accounts);
  const profileImage: string | null = useAppSelector(selectUserProfileImage);
  const isAlertMessage = useAppSelector(selectIsAlertMessage);
  const isRunningAdvertisement = useAppSelector(selectIsRunningAdvertisement);
  const autoTrader = useAppSelector((state) => state.autotrader);
  const { headerRates } = useAppSelector((state) => state.currencyRates);
  const { accountIdToPnl } = useAppSelector((state) => state.socketData);
  const location = useLocation();
  const isAlertMessageShown = (isAlertMessage && location.pathname.includes('/trade'));
  const isRunningAdvertisementShown = (isRunningAdvertisement && location.pathname.includes('/trade'))
  useBodyOverflow(openDeposit || openWithdrawal);

  const imageLink = `${process.env.REACT_APP_IMAGES_URL}${profileImage}`

  const selectedAccount = useMemo(() => (
    accounts.find((account) => account.isActive) ?? null
  ), [accounts]);

  const equity = (selectedAccount?.balance ?? 0) + pnl + (selectedAccount?.credit ?? 0);

  const symbolIcon = useMemo(() => (getCurrencySymbol(selectedAccount?.cfdAccountCurrency.symbol ?? '')), [selectedAccount])

  useEffect(() => {
    dispatch(getHeaderCurrencyRates());
  }, []);

  useEffect(() => {
    if (userId !== undefined) {
      dispatch(getAutotrader(userId));
      const isSessionToken = sessionStorage.getItem('token');

      socket.emit('startStreaming', { userId, justSubscribe: isSessionToken });

      socket.on(`onLogoutUser&${userId}`, () => { logout(userId); });
      socket.on(`reciveTransactionNotification&${userId}`, ({ type, message }) => {
        const openParams = {
          message,
          header: `The ${type} success!`,
          modalType: 'transaction',
          position: { vertical: 'bottom', horizontal: 'left' } satisfies PositionType,
          actionText: '',
          autoClose: false
        }

        handleOpen(openParams);

        Promise.allSettled([
          dispatch(getAllAccounts(userId)),
          dispatch(getTotalBalance(userId)),
          dispatch(getTotalCredit(userId))
        ])
      });

      Promise.allSettled([
        dispatch(getAllAccounts(userId)),
        dispatch(getTotalBalance(userId)),
        dispatch(getTotalCredit(userId))
      ])
    }

    return () => {
      if (userId !== undefined) {
        socket.off(`reciveTransactionNotification&${userId}`);
        socket.off(`onLogoutUser&${userId}`)
      }
    }
  }, [userId]);

  useEffect(() => {
    if (selectedAccount !== null) {
      const accountId = selectedAccount._id;
      const symbol = selectedAccount.cfdAccountCurrency.symbol

      const convertedPnl = Number(accountIdToPnl[accountId] ?? 0) * (headerRates?.[symbol] ?? 1);

      setPnl(convertedPnl);
    }
  }, [accountIdToPnl, selectedAccount])

  const depositFundsBtn = (): void => {
    setOpenDeposit(true);
  }

  const settingsBtn = (): void => {
    setOpenMenu((prev) => !prev);
  }

  const accountInfoValue: IAcountInfoValue[] = [
    { label: t('header.labels.credit'), value: `${symbolIcon} ${toFixed(selectedAccount?.credit ?? 0, 2)}` },
    { label: t('header.labels.equity'), value: `${symbolIcon} ${toFixed(equity, 2)}` },
    {
      label: t('header.labels.pnl'),
      value: `${symbolIcon} ${toFixed(pnl, 2)}`,
      style: { color: pnl >= 0 ? 'var(--CFD-theme-Custom-Green)' : 'var(--CFD-theme-Custom-Red)' }
    }
  ];

  const selectAccountHandler = (): void => {
    setOpenSelectAccounts(!openSelectAccounts);
  }

  const TransactionModal = useMemo(() => {
    return (
      <TransactionModalContext
        open={ openDeposit }
        setOpen={ setOpenDeposit }
        component={
          <DepositComponent setOpen={ setOpenDeposit } selectedAccount={ chosenSelectedAccount ?? selectedAccount } />
        }
      />
    )
  }, [openDeposit, selectedAccount, chosenSelectedAccount]);

  const WithdrawalModal = useMemo(() => {
    return (
      <TransactionModalContext
        open={ openWithdrawal }
        setOpen={ setOpenWithdrawal }
        component={
          <WithdrawalComponent setOpen={ setOpenWithdrawal } selectedAccount={ chosenSelectedAccount ?? selectedAccount } />
        }
      />
    )
  }, [openWithdrawal, selectedAccount, chosenSelectedAccount]);

  const MenuSetting = useMemo(() => {
    return openMenu && <MenuSettingsComponent setOpenMenu={ setOpenMenu } />
  }, [openMenu])

  const SelectAccountDropdown = useMemo(() => {
    return openSelectAccounts && <SelectAccountHeader
      setOpenSelectAccounts={ setOpenSelectAccounts }
      setOpenDeposit={ setOpenDeposit }
      setOpenWithdrawal={ setOpenWithdrawal }
      setChosenSelectedAccount={ setChosenSelectedAccount }
      selectedAccount={ selectedAccount }
    />
  }, [openSelectAccounts, selectedAccount, openSelectAccounts]);

  return (
    <DialogContextProvider >
    <>
      <div
        className={`${style.wrapper} ${style[`wrapper-${theme}`]}`}
        style={{
          position: (isAlertMessageShown || isRunningAdvertisementShown) ? 'absolute' : 'fixed'
        }}
      >
        <div className={ style.headerLiveMobileLogo }>
          {
            ((brand?.landingUrl) != null)
              ? <a href={ brand?.landingUrl } target="_blank" rel="noreferrer"><LogoIcon className={style.logo}/></a>
              : <LogoIcon className={style.logo}/>
          }
        </div>

        <div className={ style.headerLiveMobileOptions }>
          <div className={ style.headerLiveMobileBtn }>
            <FabComponent variant="extended" size='small' onClick={ depositFundsBtn }>
              <svg className={ style.svg }>
                <use xlinkHref={`${reciveDeposit}#reciveDeposit`}/>
              </svg>
              <span className={style.headerTitle}>{t('header.deposit_button_title')}</span>
            </FabComponent>
          </div>

          <div className={ style.accountInfoWrapper }>
            <div className={ style.infoWrapperSelect }>
              <button
                className={ style.infoWrapperSelectBtn }
                onClick={ selectAccountHandler }
                style={{
                  backgroundColor: openSelectAccounts ? 'var(--CFD-theme-State-layers-secondary-opacity_12)' : ''
                }}
              >
                <AccountBalanceWalletIcon sx={ accountSelectBtnIconStyle }/>
                <div className={ style.infoWrapperSelectBtnContent }>
                  <p className={ style.infoWrapperSelectBalance }>{`${symbolIcon} ${toFixed(selectedAccount?.balance ?? 0, 2)}`}</p>
                  <p className={ style.infoWrapperSelectTitle }>
                    { selectedAccount?.type.name ?? '' } {t('labels.account')} { selectedAccount?.cfdAccountCurrency?.symbol ?? '' }
                  </p>
                </div>
                <div className={ style.infoWrapperSelectIconWrapper }>
                  {
                    openSelectAccounts
                      ? <KeyboardArrowUpIcon sx={ accountSelectBtnIconStyle }/>
                      : <KeyboardArrowDownIcon sx={ accountSelectBtnIconStyle }/>
                  }
                </div>
                <div className={ style.infoWrapperSelectIconMobile }>
                  <KeyboardArrowRightIcon sx={ accountSelectBtnIconStyle } />
                </div>
              </button>
            </div>
            <div className={ style.accountInfoBallanceWrapper }>
              <p className={ style.accountInfoBallanceAmount }>
                {`${symbolIcon} ${toFixed(selectedAccount?.balance ?? 0, 2)}`}
              </p>
            </div>
            {
              accountInfoValue.map((item, index) => (
                <div className={ style.accountInfo } key={ index }>
                  <p className={ style.accountInfoTitle }>{ item.label }</p>
                  <p className={ style.accountInfoAmount } style={ item.style }>{ item.value }</p>
                </div>
              ))
            }
          </div>

          <div className={style['header-menu-wrapper']}>
            <div
              className={style['header-user-wrapper']}
              onClick={ settingsBtn }
              style={{ backgroundColor: openMenu ? 'var(--CFD-theme-State-layers-secondary-opacity_16)' : '' }}
            >
              <div className={style['user-info-wrapper']}>
                <span className={style['user-info-name']}>{`${firstName} ${lastName}` }</span>
              </div>
              {
                profileImage === null
                  ? firstName !== undefined && firstName.length > 0 && (
                      <div className={style.userIcon}>
                        {firstName[0]}
                      </div>
                  )
                  : <Avatar sx={{ width: 36, height: 36 }} alt={firstName} src={imageLink} />
              }
            </div>
          </div>
        </div>

      </div>
      { SelectAccountDropdown }
      { MenuSetting }
      { TransactionModal }
      { WithdrawalModal }
   </>
   </DialogContextProvider>
  )
}
